import React, { ReactElement, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles, Theme } from '@material-ui/core'
import { PaymentType } from '../../models/payment'
import PaymentComponent from './payment.component'

type PaymentTypeProps = {
  data: PaymentType
  type: string
}

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),

  },
  listWrapper: {
    borderRadius: '5px',
    '&:nth-child(odd)': {
      background: theme.palette.action.hover,
    },
  },
}))
export default function PaymentTypesComponent({
  type,
  data,
}: PaymentTypeProps): ReactElement {
  const classes = useStyles()
  const [childOpen, setChildOpen] = useState(false)
  const handleClick = (): void => {
    setChildOpen(!childOpen)
  }
  return (
    <>
      <List component="div" className={classes.listWrapper} disablePadding>
        <ListItem className={classes.nested} button onClick={handleClick}>
          <ListItemText
            primary={type}
            secondary={`$${data.total.toFixed(2)}`}
          />
          {childOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {data.payments.map((payment) => {
          return (
            <PaymentComponent
              data={payment}
              open={childOpen}
              key={payment.id}
            />
          )
        })}
      </List>
    </>
  )
}
