// expects time formatted as "mm:ss"
import moment from 'moment'
import polyfills from './polyfills'

export type PaymentFilterData = {
  month: number,
  year: number,
  user?: number
}

// returns time in milliseconds
export const minSecondToNumber = (time: string): number => {
  const [minutes, seconds] = time.split(':')
  const totalSeconds = +minutes * 60 + +seconds
  return totalSeconds * 1000
}

// expects time in milliseconds
// returns time as "mm:ss"
export const getTimeAsString = (duration: number): string => {
  const durInSeconds = duration / 1000
  const min = Math.floor(durInSeconds / 60)
  const sec = Math.floor(durInSeconds % 60)
  if (sec < 10) return `${min}:0${sec}`
  return `${min}:${sec}`
}

export const getTimeAsStringWith0 = (duration: number): string => {
  const durationString = getTimeAsString(duration)
  if (durationString.length === 4) return `0${durationString}`
  return durationString
}

export const getDateRange = (paymentFilter: PaymentFilterData): {start: string, end: string} => {
  const { year, month } = paymentFilter
  const startDate = moment(`${year}-${+month + 1}-01`, 'YYYY-M-DD')
  return { start: startDate.format('M-D-YYYY'), end: startDate.endOf('month').format('M-D-YYYY') }
}

export const getDefaultFilter = (): {month: number, year: number} => {
  const currentDate = new Date()
  const urlParams = polyfills.URLSearchParams(window.location.search)
  const [paramMonth, paramYear] = [urlParams.get('month'), urlParams.get('year')]
  const [defaultMpnth, defaultYear] = [currentDate.getMonth(), currentDate.getFullYear()]
  return {
    month: +paramMonth || defaultMpnth,
    year: +paramYear || defaultYear,
  }
}
