import React, { ReactElement } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'
import { Payment } from '../../models/payment'
import { colors } from '../../styles/colors'

type PaymentProps = {
  data: Payment
  open: boolean
}
const useStyles = makeStyles((theme: Theme) => ({
  paymentWrapper: {
    paddingLeft: theme.spacing(4),
    backgroundColor: colors.backgroundBlue,
  },
  title: {
    fontSize: '.875rem',
  },
}))

const getBriefNote = (note: string | undefined): string => {
  const maxLength = 50
  if (!note) return ''
  const prependDash = (x: string): string => ` - ${x}`
  return note.length < maxLength ? prependDash(note) : prependDash(`${note.slice(0, maxLength)}...`)
}
export default function PaymentComponent({
  data,
  open,
}: PaymentProps): ReactElement {
  const classes = useStyles()
  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.paymentWrapper} onClick={async (): Promise<void> => navigate(`customers/${data.customer_id}`)}>
            <ListItemText
              primary={data.line_item}
              primaryTypographyProps={{ className: classes.title }}
              secondary={(
                <>
                  <Typography
                    component="span"
                    variant="caption"
                  >
                    {`$${data.amount}`}
                  </Typography>
                  {getBriefNote(data.notes)}
                </>
              )}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}
