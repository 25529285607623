import axios from '../utils/axios'
import { UserPayments, Paystub } from '../models/payment'
import { getDateRange } from '../utils/time'

export type PaymentFilter = {
  month: number
  year: number
}

export const getPayments = async (paymentFilter: PaymentFilter): Promise<UserPayments> => {
  const { start, end } = getDateRange(paymentFilter)
  const res: ApiResponse = await axios.get(`payments?start=${start}&end=${end}`)
  return res.data
}

export const getPaystub = async (date: string): Promise<Paystub> => {
  const res: ApiResponse = await axios.get(`payments/${date}`)
  return res.data
}

export const getLatestPaymentMonth = async (): Promise<number> => {
  const res: ApiResponse = await axios.get('payments/latest_payment_month')
  return res.data
}
